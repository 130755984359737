<style lang="scss">
.digitalesprospekt {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;
    &:after {
    }
    span {
      display: inline-block;
      background: $blue-darker;
      color: #fff;
      padding: 8px 10px;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    .content-link-mehrinfo {
      transition: all 0.2s ease;
      display: flex;
      span {
        position: relative;
        top: 10px;
        display: block;
        margin-right: 5px;
      }
      &:hover {
        padding-left: 10px;
        color: $font-color;
        opacity: 0.8;
      }
    }
  }
}
</style>

<template>
  <div class="content digitalesprospekt container">
    <h1>Die digitale Erweiterung Ihres Prospekts</h1>
    <p class="intro-txt">Ohne großen Zusatzaufwand nutzen Sie Ihr Prospekt auch digital und damit maximal effizient.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-erweiterung-digitales-prospekt.jpg" alt="Erweiterung Digitales Prospekt" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Digitales Prospekt</h2>
          <p>Dank der digitalen Möglichkeiten entkommt Ihnen ab sofort kein Prospektverweigerer mehr.</p>
          <div class="preis-box">
            <span>Produktpreis ab <strong>€ 500,00 </strong></span>
          </div>
          <CollapsibleButton link="calender-ad-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum digitalen Prospekt</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="calender-ad-collapse">
      dasfasdfa
    </Collapsible>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'ErweiterungDigitalesProspekt',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
